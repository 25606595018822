.hero {
  position: relative;
  background-image: url("../../../../public/images/bk2.jpg");
  background-size: cover;
  /* background-attachment: fixed; */
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 15%;
  padding-right: 50px;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.4); */
  z-index: -1; /* Place behind content */
}

.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .margin {
    height: 0vh;
  }
  .hero {
    background-position: center;
    padding-top: 50%;

    height: 80vh;
  }

  .hero {
    background-image: url("../../../../public/images/bk2.jpg");
    /* background-size: cover; */
    /* background-attachment: fixed; */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    padding-top: 20%;
    padding-right: 50px;
    color: #fff;
  }

  .hero .row {
    width: 100%;
  }
}


@media screen and (max-width: 1268px) {
  .margin {
    height: 0vh;
  }

  .hero {
    background-image: url("../../../../public/images/bk2.jpg");
    /* background-size: cover; */
    /* background-attachment: fixed; */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    padding-top: 23%;
    padding-right: 50px;
    color: #fff;
  }

  .hero .row {
    width: 100%;
  }
}
